import React, {useContext} from 'react';
import {Modals, PageTemplate} from '~src/components';
import {LocaleContext} from '~src/context';
import {IPage} from '~src/types';
import {Result, Button} from 'antd';
import {Link} from 'gatsby';
import Seo from '~src/components/Seo';

interface INotFoundPage extends IPage {}

const NotFoundPage: React.FC<INotFoundPage> = ({location, navigate, path}) => {
  const {
    pages: {notFoundPage},
  } = useContext(LocaleContext);
  return (
    <PageTemplate>
      <Seo title={notFoundPage.meta.title} />
      <Result
        status="404"
        title="404"
        subTitle={notFoundPage.title}
        extra={
          <Link to="/">
            <Button type="primary">{notFoundPage.buttonText}</Button>
          </Link>
        }
      />
      <Modals navigate={navigate} path={path} location={location} />
    </PageTemplate>
  );
};

export default NotFoundPage;
